<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/tagList' }">網域標籤列表</el-breadcrumb-item>
                <el-breadcrumb-item>新增網域標籤</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="container">
            <div class="form-box">
                <div class="form-title">新增網域標籤</div>
                <el-form ref="formName"
                         :model="form"
                         :rules="rules"
                         label-width="120px"
                         label-position="left"
                         status-icon
                         @submit.prevent>
                    <el-form-item label="標籤名稱"
                                  prop="tag">
                        <el-input v-model="form.tag"></el-input>
                    </el-form-item>
                    <el-form-item label="標籤名稱說明"
                                  prop="tag_description">
                        <el-input type="textarea"
                                  placeholder="请输入内容"
                                  maxlength="150"
                                  show-word-limit
                                  v-model="form.tag_description"></el-input>
                    </el-form-item>

                    <div class="submit-btn">
                        <el-button type="primary"
                                   @click="onSubmit('formName')">確認</el-button>
                        <el-button @click="resetForm">重填</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { tagCreate } from '../../api/index'
export default {
    name: 'createTag',
    data() {
        return {
            form: {},
            rules: {
                tag: [
                    {
                        required: true,
                        message: '请输入標籤名稱',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {},
    mounted() {},
    methods: {
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    tagCreate(this.form)
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message.success('提交成功！')
                                this.$router.push({ path: 'tagList' })
                            } else if (res.code == 500) {
                                this.$message.error(res.message)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            this.$message.error('失敗')
                        })
                } else {
                    this.$message.error('失敗')
                    return false
                }
            })
        },
        resetForm() {
            this.form = []
        },
    },
}
</script>

<style lang="scss" scoped>
.form-box {
    margin: auto;
    width: 100%;
}
.form-title {
    text-align: center;
    font-size: 18px;
    padding-bottom: 20px;
}
.submit-btn {
    justify-content: center;
    display: flex;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .form-box {
        width: 600px;
    }
}
</style>
 <style lang="scss">
.el-textarea__inner {
    min-height: 125px !important;
}
</style>